<template>
  <table class="invoices-table table">
    <thead>
      <tr>
        <td class="table-res">
          <label class="custom-control custom-checkbox">
            <input
              type="checkbox"
              class="custom-control-input"
              @change="checkAll = !checkAll"
            />
            <span class="custom-control-indicator"></span>
          </label>
        </td>
        <th>
          {{ activeLanguage.invNr }}
          <!--<i class="fa fa-sort" aria-hidden="true"></i>-->
        </th>
        <th>
          Username
          <!--<i class="fa fa-sort" aria-hidden="true"></i>-->
        </th>
        <th class="hidden-xs hidden-sm">
          {{ activeLanguage.client }}
          <!--<i class="fa fa-sort" aria-hidden="true"></i>-->
        </th>
        <th class="table-res hidden-xs hidden-sm">
          {{ activeLanguage.invDate }}
          <!--<i class="fa fa-sort" aria-hidden="true"></i>-->
        </th>
        <th class="fixedwidth amount">
          {{ activeLanguage.amount }}
          <!--<i class="fa fa-sort" aria-hidden="true"></i>-->
        </th>
        <th class="text-center">
          {{ activeLanguage.download }}
        </th>
      </tr>
    </thead>
    <tbody>
      <template v-for="inv in invoices">
        <tr>
          <td class="table-res">
            <label class="custom-control custom-checkbox">
              <input
                type="checkbox"
                class="custom-control-input"
                v-model="inv.checked"
                @change="invoiceIsSelected(inv)"
              />
              <span class="custom-control-indicator"></span>
            </label>
          </td>
          <td>
            #{{ inv.number }}
            <div class="small-date">{{ inv.date }}</div>
          </td>
          <td>
            <div class="overflow">
              {{ inv.username }}
            </div>
          </td>
          <td class="hidden-xs hidden-sm">
            {{ inv.client }}
          </td>
          <td class="table-res hidden-xs hidden-sm">{{ inv.date }}</td>
          <td class="amount">
            <span>€</span>
            {{ formattedAmount(inv.amount) }}
          </td>
          <td class="text-center">
            <a
              @click.prevent="showDownloadRow(inv)"
              href="#"
              class="download-link"
              ><i class="fa fa-download" aria-hidden="true"></i
            ></a>
          </td>
        </tr>
        <tr class="table-res" v-if="inv.showDownload">
          <td colspan="7" class="download-row">
            <div class="download-collapse collapse show">
              <div class="form-row pt-2">
                <div class="form-group col-md-7 col-6">
                  <select
                    class="form-control"
                    style="height: 34px"
                    @change="fileTypeSelected($event, inv)"
                  >
                    <template v-if="inv.options.paynl_specification">
                      <option
                        v-for="item in activeLanguage.selectOptions"
                        :value="item"
                        v-if="item.includes('Buckaroo') === false"
                        :key="item.id"
                      >
                        {{ item }}
                      </option>
                    </template>
                    <template v-if="inv.options.buckaroo_specification">
                      <option
                        v-for="item in activeLanguage.selectOptions"
                        :value="item"
                        v-if="!item.includes('PayNL')"
                        :key="item.id"
                      >
                        {{ item }}
                      </option>
                    </template>
                    <template v-if="inv.options.split_invoice_pdf">
                      <option :value="activeLanguage.receiving" :key="inv.id">
                        {{ activeLanguage.receiving }}
                      </option>
                      <option :value="activeLanguage.costs">
                        {{ activeLanguage.costs }}
                      </option>
                    </template>
                    <template
                      v-if="
                        !inv.options.paynl_specification &&
                        !inv.options.buckaroo_specification
                      "
                    >
                      <option
                        v-for="item in activeLanguage.selectOptions"
                        :value="item"
                        v-if="
                          !item.includes('PayNL') &&
                          item.includes('Buckaroo') === false
                        "
                        :key="item.id"
                      >
                        {{ item }}
                      </option>
                    </template>
                  </select>
                </div>
                <div class="form-group col-md-5 col-6">
                  <button @click="downloadInv(inv)" class="btn">
                    {{ activeLanguage.downloadBtn }}
                  </button>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "invoices-table",
  props: {
    activeLanguage: {
      type: Object,
      required: true,
    },
    invoices: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      types: [
        {
          type: "main",
          ext: "pdf",
          textEn: "Invoice (PDF)",
        },
        {
          type: "match-entry-bundled",
          ext: "",
          textEn: "Match entries - bundled costs",
          textNl: "Match entries - gebundelde kosten",
        },
        {
          type: "match-entry-individual",
          ext: "",
          textEn: "Match entries - individual costs",
          textNl: "Match entries - individuele kosten",
        },
        {
          type: "mt940",
          ext: "",
          textEn: "MT940",
        },
        {
          type: "specs",
          ext: "pdf",
          textEn: "Costs specification (PDF)",
          textNl: "Kosten specificatie (PDF)",
        },
        {
          type: "specs",
          ext: "xlsx",
          textEn: "Costs specification (CSV)",
          textNl: "Kosten specificatie (CSV)",
        },
        {
          type: "pay-specs",
          ext: "pdf",
          textEn: "Payment specification (PDF)",
          textNl: "Betalingsspecificatie (PDF)",
        },
        {
          type: "pay-specs",
          ext: "xlsx",
          textEn: "Payment specification (CSV)",
          textNl: "Betalingsspecificatie (CSV)",
        },
        {
          type: "paynl_specs",
          ext: "xlsx",
          textEn: "PayNL specification (CSV)",
          textNl: "PayNL specificatie (CSV)",
        },
        {
          type: "buckaroo_specs",
          ext: "xlsx",
          textEn: "Buckaroo specification (CSV)",
          textNl: "Buckaroo specificatie (CSV)",
        },
        {
          type: "main",
          ext: "pdf",
          textEn: "Invoice (PDF) - Receiving",
          textNl: "Factuur (PDF) - Betalingen",
          showTemplatePart: 1,
        },
        {
          type: "main",
          ext: "pdf",
          textEn: "Invoice (PDF) - Costs",
          textNl: "Factuur (PDF) - Kosten",
          showTemplatePart: 2,
        },
      ],
      checkAll: false,
    };
  },
  computed: {
    formattedAmount() {
      return (amount) => Helper.formatAmount(amount);
    },
  },
  watch: {
    checkAll: function (val) {
      let ids = [];

      this.invoices.forEach((inv) => {
        inv.checked = val;
        if (val) {
          ids.push(inv.id);
        }
      });

      this.$emit("onInvoiceSelect", ids);
      this.$forceUpdate();
    },
  },
  methods: {
    invoiceIsSelected({ checked, id }) {
      let ids = [];

      this.invoices.forEach((order) => {
        if (order.checked) {
          ids.push(order.id);
        }
      });

      this.$emit("onInvoiceSelect", ids);
    },
    showDownloadRow(inv) {
      inv.showDownload = !inv.showDownload;
      this.$forceUpdate();
    },
    fileTypeSelected(e, inv) {
      let item = "";
      if (
        e.target.value !== "Choose specification" ||
        e.target.value !== "Kies specificatie"
      ) {
        item = this.types.find(
          (item) =>
            item.textEn === e.target.value || item.textNl === e.target.value,
        );
      }
      inv.downloadType = item;
    },
    downloadInv({ id, downloadType }) {
      if (downloadType && downloadType.type) {
        let params = {
          file_type: downloadType.type,
          file_ext: downloadType.ext,
          file: id,
          show_template_part: downloadType.showTemplatePart
            ? downloadType.showTemplatePart
            : null,
        };

        billinkAxios
          .get(`app/client-invoice/download/`, { params, responseType: "blob" })
          .then((res) => {
            let file = res.headers["content-disposition"]
                .slice(0, -1)
                .replace('inline; filename="', "")
                .split("."),
              link = document.createElement("a");

            link.href = window.URL.createObjectURL(res.data);
            link.setAttribute("download", `${file[0]}.${file[1]}`);
            document.body.appendChild(link);
            link.click();
            link.remove();
          })
          .catch((err) => {
            console.error(err);
            if (err.response.status === 400) {
              EventBus.$emit("showAlertModal", "Such File doesn't exist");
            }
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.download-collapse {
  width: 510px;
}
.invoices-table {
  th {
    vertical-align: middle;
  }
  @media (max-width: 768px) {
    .overflow {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 70px;
    }
    .small-date {
      font-size: 11px;
      display: block !important;
    }
  }
  @media (max-width: 480px) {
    th {
      text-align: center;
      font-size: 9px;
      padding: 0.4rem;
    }
    td {
      font-size: 12px;
    }
    .overflow {
      width: 54px;
    }
    .small-date {
      font-size: 8px;
    }
  }
}
</style>
